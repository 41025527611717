import { Ingredient } from '../types';

export const formatIngredientResponse = (remoteRecipe: any): Ingredient => {
  const { id, ingredient_constraints, ingredient_base, tags, title } = remoteRecipe;

  return {
    id,
    ingredientBase: ingredient_base,
    ingredientConstraints: ingredient_constraints,
    title,
    tags,
  };
};

export const formatCreateIngredient = (ingredients: any[]): any => {
  return ingredients.map((ingredient) => ({
    ingredient_data: {
      id: ingredient.ingredient_data.id,
      title: ingredient.ingredient_data.title,
    },
    quantity: ingredient.quantity,
    unit: ingredient.unit,
    preparation: ingredient.preparation,
  }));
};
