import axios from 'axios';
import { toast } from 'react-toastify';

import { getRecipes } from './recipes';
import { ReduxAction, Recipe } from '../types';
import { formatRecipeResponse, validateCreateRecipeBody, validateUpdateRecipeBody } from '../utils/recipe';

const { GATSBY_API_BASE_URL } = process.env;

export const getRecipe = (id: string) => async (dispatch) => {
  try {
    const res = await axios.get(`${GATSBY_API_BASE_URL}/recipes/${id}`);
    const recipe = formatRecipeResponse(res.data);
    dispatch(getRecipeSuccess(recipe));
  } catch (err) {
    dispatch(getRecipeFailure(err.message));
  }
};

export const createRecipe = (recipe: Recipe) => async (dispatch) => {
  try {
    dispatch(createRecipeError(undefined));

    const validatedBody = validateCreateRecipeBody(recipe);

    const res = await axios.post(`${GATSBY_API_BASE_URL}/recipes`, validatedBody);
    const formattedRecipe = formatRecipeResponse(res.data);

    dispatch(createRecipeSuccess(formattedRecipe));
    toast.success(`Created recipe: ${formattedRecipe.title}`);
  } catch (err) {
    dispatch(createRecipeError(err.message));
    toast.error(err.message);
  }
};

export const updateRecipe = (id: string, recipe: Partial<Recipe>) => async (dispatch) => {
  try {
    const validatedBody = validateUpdateRecipeBody(recipe);

    const res = await axios.put(`${GATSBY_API_BASE_URL}/recipes/${id}`, validatedBody);
    const formattedRecipe = formatRecipeResponse(res.data);

    dispatch(updateRecipeSuccess(formattedRecipe));
    toast.success(`Updated recipe: ${formattedRecipe.title}`);
  } catch (err) {
    dispatch(updateRecipeError(err.message));
    toast.error(err.message);
  }
};

export const publishRecipe = (id: string) => async (dispatch) => {
  try {
    const res = await axios.put(`${GATSBY_API_BASE_URL}/cms/publish/${id}`);
    const formattedRecipe = formatRecipeResponse(res.data);
    dispatch(createRecipeSuccess(formattedRecipe));
    dispatch(getRecipes());

    toast.success(`Published recipe: ${formattedRecipe.id}`);
  } catch (err) {
    dispatch(createRecipeError(err.message));
    toast.error(err.message);
  }
};

export const unpublishRecipe = (id: string) => async (dispatch) => {
  try {
    const res = await axios.put(`${GATSBY_API_BASE_URL}/cms/unpublish/${id}`);
    const formattedRecipe = formatRecipeResponse(res.data);

    dispatch(createRecipeSuccess(formattedRecipe));
    dispatch(getRecipes());
    toast.success(`Unpublished recipe: ${formattedRecipe.id}`);
  } catch (err) {
    dispatch(createRecipeError(err.message));
    toast.error(err.message);
  }
};

export const uploadRecipeImages = (id: string, data: FormData) => async (dispatch) => {
  try {
    await axios.post(`${GATSBY_API_BASE_URL}/recipes/${id}/uploadImage`, data);
    dispatch(uploadRecipeImageSuccess());
    dispatch(getRecipe(id));

    toast.success(`Added images to recipe: ${id}`);
  } catch (err) {
    dispatch(uploadRecipeImageError());
  }
};

// Use to mutate recipe in the store before sending to api
export const updateRecipeNoFetch = (id: string, data: Recipe) => async (dispatch) => {
  dispatch(updateRecipeSuccess(data));
}

export const clearRecipe = () => async (dispatch) => {
  try {
    dispatch(clearRecipeFromState());
  } catch (err) {
    dispatch(uploadRecipeImageError());
  }
};

export const deleteRecipe = (id: string) => async (dispatch) => {
  try {
    await axios.delete(`${GATSBY_API_BASE_URL}/recipes/${id}`);
    dispatch(deleteRecipeSuccess());
    dispatch(getRecipes());

    toast.success(`Deleted recipe: ${id}`);
  } catch (err) {
    dispatch(deleteRecipeError());
  }
};

const getRecipeSuccess = (recipe: Recipe): ReduxAction<Recipe> => ({ type: 'GET_RECIPE_SUCCESS', payload: recipe });
const getRecipeFailure = (recipe: Recipe): ReduxAction<Recipe> => ({ type: 'GET_RECIPE_ERROR', payload: recipe });

const createRecipeSuccess = (recipe: Recipe): ReduxAction<Recipe> => ({
  type: 'CREATE_RECIPE_SUCCESS',
  payload: recipe,
});
const createRecipeError = (errorMessage: string): ReduxAction<string> => ({
  type: 'CREATE_RECIPE_ERROR',
  payload: errorMessage,
});

const updateRecipeSuccess = (recipe: Recipe): ReduxAction<Recipe> => ({
  type: 'UPDATE_RECIPE_SUCCESS',
  payload: recipe,
});
const updateRecipeError = (recipe: Recipe): ReduxAction<Recipe> => ({ type: 'UPDATE_RECIPE_ERROR', payload: recipe });

const uploadRecipeImageSuccess = (): ReduxAction<null> => ({
  type: 'UPLOAD_IMAGE_SUCCESS',
});
const uploadRecipeImageError = (): ReduxAction<null> => ({
  type: 'UPLOAD_IMAGE_ERROR',
});
const clearRecipeFromState = (): ReduxAction<null> => ({
  type: 'CLEAR_RECIPE',
});

const deleteRecipeSuccess = (): ReduxAction<Recipe> => ({
  type: 'DELETE_RECIPE_SUCCESS',
});
const deleteRecipeError = (): ReduxAction<Recipe> => ({ type: 'DELETE_RECIPE_ERROR' });
