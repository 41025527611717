import axios from 'axios';

import { ReduxAction, RecipeList } from '../types';
import { formatCMSRRecipeResponse } from '../utils/recipe';

const { GATSBY_API_BASE_URL } = process.env;

export const getRecipes = (query: string) => async (dispatch) => {
  try {
    const res = await axios.get(`${GATSBY_API_BASE_URL}/cms/recipes${query ? "?search=" + query : ''}`);
    const recipes = res.data.map(formatCMSRRecipeResponse);

    dispatch(getRecipesSuccess(recipes));
  } catch (err) {
    dispatch(getRecipesFailure(err.message));
  }
};

export const getRecipesSearch = (search?: string) => async (dispatch) => {
  try {
    let url = `${GATSBY_API_BASE_URL}/cms/recipes/`;

    if (search) {
      url += `?search=${encodeURIComponent(search)}`;
    }

    const res = await axios.get(url);
    const recipes = res.data.map((recipe) => {
      return recipe;
    });

    dispatch(getRecipesSearchSuccess(recipes));
  } catch (err) {
    dispatch(getRecipesSearchFailure(err.message));
  }
};

const getRecipesSuccess = (recipes: RecipeList): ReduxAction<RecipeList> => ({
  type: 'GET_RECIPES_SUCCESS',
  payload: recipes,
});
const getRecipesFailure = (recipes: RecipeList): ReduxAction<RecipeList> => ({
  type: 'GET_RECIPES_ERROR',
  payload: recipes,
});

const getRecipesSearchSuccess = (recipes: RecipeList): ReduxAction<RecipeList> => ({
  type: 'GET_RECIPES_SEARCH_SUCCESS',
  payload: recipes,
});

const getRecipesSearchFailure = (recipes: RecipeList): ReduxAction<RecipeList> => ({
  type: 'GET_RECIPES_SEARCH_FAILURE',
  payload: recipes,
});
