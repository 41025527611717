import { Recipe, RecipeListRecipe, FeaturedRecipe } from '../types';
import { formatCreateIngredient } from './ingredient';

export const validateCreateRecipeBody = (remoteRecipe: any): Recipe => {
  // Remove empty ingredients from list
  const recipeIngredients = remoteRecipe.ingredients.filter((ingredient) => !!ingredient.ingredient_data.id);

  remoteRecipe.ingredients = recipeIngredients;
  remoteRecipe.authorId = remoteRecipe.author.id;

  return remoteRecipe;
};

export const validateUpdateRecipeBody = (remoteRecipe: any): Recipe => {
  const ingredients = formatCreateIngredient(remoteRecipe.ingredients);
  remoteRecipe.ingredients = ingredients;

  remoteRecipe.authorId = remoteRecipe.author.id;

  return remoteRecipe;
};

export const formatRecipeResponse = (remoteRecipe: any): Recipe => {
  const {
    author,
    cook_time,
    cuisine,
    description,
    id,
    images,
    preparation,
    recipe_ingredient,
    recipe_status,
    serving_size,
    title,
  } = remoteRecipe;

  return {
    author,
    cookTime: cook_time,
    cuisine,
    description,
    id,
    images,
    preparation,
    ingredients: recipe_ingredient,
    recipeStatus: recipe_status,
    servingSize: serving_size,
    title,
  };
};

export const formatCMSRRecipeResponse = (remoteRecipe: any): RecipeListRecipe => {
  const { id, title, author, recipe_status } = remoteRecipe;

  return {
    id,
    title,
    author,
    recipeStatus: recipe_status,
  };
};

export const formatFeaturedRecipeResponse = (remoteRecipe: any): FeaturedRecipe => {
  const { id, recipe_id, image_url, active, recipe_data, display_order } = remoteRecipe;

  return {
    id,
    recipeID: recipe_id,
    imageURL: image_url,
    active,
    title: recipe_data.title,
    displayOrder: display_order,
  };
};
